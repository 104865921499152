const ContainerWithShadow = (props) => {
  const { containerWidthClass, containerHeightClass, className, noShadow = false } = props;

  return (
    <div className={`overflow-hidden ${noShadow ? "shadow-none" : "b-shadow"} flex justify-center`}>
      <div
        className={`${containerWidthClass} ${containerHeightClass} py-[72px] lg:mx-auto sm:mx-[60px] xxs:mx-6 ${className}`}
      >
        {props?.children}
      </div>
    </div>
  );
};
export default ContainerWithShadow;
